import ApplicationLogo from '@/Components/ApplicationLogo';
import Checkbox from '@/Components/Checkbox';
import DangerButton from '@/Components/DangerButton';
import InputError from '@/Components/InputError';
import InputLabel from '@/Components/InputLabel';
import PrimaryButton from '@/Components/PrimaryButton';
import TextInput from '@/Components/TextInput';
import GuestLayout from '@/Layouts/GuestLayout';
import { Head, Link, useForm } from '@inertiajs/react';

export default function Login({ status, canResetPassword }) {
    const { data, setData, post, processing, errors, reset } = useForm({
        email: '',
        password: '',
        remember: false,
    });

    const submit = (e) => {
        e.preventDefault();

        post(route('login'), {
            onFinish: () => reset('password'),
        });
    };

    return (
        <GuestLayout>

            <div className="authentication-wrapper authentication-basic container-p-y">
                <div className="authentication-inner py-6">
                    <div className="card">
                        <div className="card-body">
                            <div className="app-brand justify-content-center mb-6">
                                <Link href={route('home')} className="app-brand-link">
                                    <span className="app-brand-logo demo">
                                        <ApplicationLogo className="h-100 w-100 fill-current text-gray-500" />
                                    </span>
                                    <span className="app-brand-text demo text-heading fw-bold">IMM</span>
                                </Link>
                            </div>
                            <h4 className="mb-1">Welcome to IMM! 👋</h4>
                            <p className="mb-6">Please sign-in to your account and start the adventure</p>

                            {status && (
                                <p className="mb-6 text-success">
                                    {status}
                                </p>
                            )}
                            <form onSubmit={submit} id="formAuthentication" className="mb-4">
                                <div className="mb-6">
                                    <InputLabel htmlFor="email" value="Email Address" />

                                    <TextInput
                                        id="email"
                                        type="email"
                                        name="email"
                                        value={data.email}
                                        className="mt-1 block w-full"
                                        autoComplete="username"
                                        isFocused={true}
                                        onChange={(e) => setData('email', e.target.value)}
                                    />

                                    <InputError message={errors.email} className="mt-2" />
                                </div>

                                <div className="mb-6 form-password-toggle">
                                    <InputLabel htmlFor="password" value="Password" />

                                    <TextInput
                                        id="password"
                                        type="password"
                                        name="password"
                                        value={data.password}
                                        className="mt-1 block w-full"
                                        autoComplete="password"
                                        isFocused={false}
                                        onChange={(e) => setData('password', e.target.value)}
                                    />

                                    <InputError message={errors.password} className="mt-2" />
                                </div>
                                <div className="my-8">
                                    <div className="d-flex justify-content-between">
                                        <div className="form-check mb-0 ms-2">
                                            <Checkbox
                                                name="remember"
                                                id="remember"
                                                checked={data.remember}
                                                onChange={(e) =>
                                                    setData('remember', e.target.checked)
                                                }
                                            />

                                            <label className="form-check-label" htmlFor="remember"> Remember Me </label>
                                        </div>
                                        {canResetPassword && <Link href={route('password.request')}>
                                            <p className="mb-0">Forgot Password?</p>
                                        </Link>}
                                    </div>
                                </div>
                                <div className="mb-6">
                                    <PrimaryButton
                                        className='w-100' disabled={processing}>Login</PrimaryButton>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </GuestLayout>
    );
}
